﻿import {get, $, $$, postDataText, postData, toggle, show, cookieConsentsLoaded, getAndSetCookieConsents} from "@/Utils"
import {
    addRemoveComparison,
    initComparisonPartial,
    initComparisons,
    initFavorites
} from "@/ProductModule"
import { initTabs } from "@/sharedComponents/Tabs"
import { initCarousels } from "@/sharedComponents/Carousel/Carousel"
import { initProductModals } from "@/sharedComponents/Modal"
import { initLeaseCalculator } from "@/sharedComponents/LeaseCalculator"
import { initGoogleMap } from "@/StoreMap"
import { fillPanelAndInitForm } from "@/sharedComponents/Header/CustomerService"
import {CustomerSupportSubType, EditCartFrom, KlaviyoEvents} from "@/enums"
import { tns, TinySliderSettings } from "tiny-slider/src/tiny-slider"
import {StatusCodes} from "http-status-codes";
import {initProductReviewButtons} from "@/views/Account/Account";
import {KlaviyoViewedProduct} from "@/interfaces";


const tryOpenTabFromUrl = () => {
  const id = window.location.hash;
  if (id) {
    const tab = $(`.tabs-menu ${id}`)
    if (tab) {
      tab.click();
      window.scrollTo({ top: tab.offsetTop, behavior: 'smooth' });
    }
  }
}

function initProduct() {
  const infoText = $(".product__info__text");
  const readMore = infoText?.parentElement?.querySelector(".read__more") as HTMLElement;

  if (!infoText || !readMore) return;

  const productInfo = readMore.parentElement;
  productInfo.classList.toggle("product__info--expanded", infoText.scrollHeight <= infoText.clientHeight);
  readMore.addEventListener("click", () => productInfo.classList.add("product__info--expanded"));
}

function initCardComparisons() {
  $$("[data-card-add-remove-id]").forEach(x => {
    x.addDataBoundEventListener("click", () => {
      // toggle button on the product page.
      const add = get("comparisonToggle").classList.toggle("active");
      const productId = parseInt(x.getAttribute("data-card-add-remove-id"));
      addRemoveComparison(add, productId);
    });
  });
  initComparisonPartial();

}
function initCardFavorites() {
  $$("[data-card-favorite-id]").forEach(x => {
    x.addDataBoundEventListener("click", () => {
      const add = get("favoriteToggle").classList.toggle("active");
      const productId = parseInt(x.getAttribute("data-card-favorite-id"));
      const url = add ? window.config.addFavoriteUrl : window.config.removeFavoriteUrl;
      const callback = (resp: Response) => {
          toggle(get("panel-saved-products"), resp.status !== StatusCodes.NO_CONTENT);
      };
      postData(url, productId, callback);
    });
  });
}

function initRatingStars() {
    get("rating-stars")?.addEventListener("click", () => {
        const discussionTabLink = get("_DiscussionTabLink");
        discussionTabLink?.click();
        discussionTabLink?.scrollIntoView();
    });
}

function displayOriginalOrTranslatedReview(displayButton: Element) {
    const closestReview = displayButton.closest(".discussion-card__item");
    const translatedReview = closestReview.querySelector(".translated-review");
    const originalReview = closestReview.querySelector(".original-review");
    const translationInfo = closestReview.querySelectorAll(".translation-info");
    const displayOriginalButton = closestReview.querySelector(".display-original");
    const displayTranslationButton = closestReview.querySelector(".display-translation");
    translatedReview.classList.toggle("d-none");
    originalReview.classList.toggle("d-none");
    translationInfo.forEach(x => x.classList.toggle("d-none"));
    displayOriginalButton.classList.toggle("d-none");
    displayTranslationButton.classList.toggle("d-none");
}

function initDisplayOriginalOrTranslated() {
    $$(".display-original")?.forEach(x => x.addEventListener("click", () => {
        displayOriginalOrTranslatedReview(x.closest(".discussion-card__item"));
    }));
    $$(".display-translation")?.forEach(x => x.addEventListener("click", () => {
        displayOriginalOrTranslatedReview(x.closest(".discussion-card__item"));
    }));
}

function initLikes() {
  $$("[data-like-id]")?.forEach(x => x.addEventListener("click", () => {
    const discussionId = parseInt(x.getAttribute("data-like-id"));
    const callback = function (resp: string) {
      x.closest(".feedback").innerHTML = resp;
    }
    postDataText(window.config.likeDiscussionUrl, discussionId, callback);
  }));
}

function initDislikes() {
    $$("[data-dislike-id]")?.forEach(x => x.addEventListener("click", () => {
        const discussionId = parseInt(x.getAttribute("data-dislike-id"));
        const callback = function (resp: string) {
            x.closest(".feedback").innerHTML = resp;
        }
        postDataText(window.config.dislikeDiscussionUrl, discussionId, callback);
    }));
}

function initDiscussions() {
  initLikes();
  initDislikes();
}

function initProductAvailabilityPrompt() {
  get("ask-product-availability")?.addEventListener("click", (e) => {
    const productId = parseInt((e.target as HTMLElement).getAttribute("data-product-id"));
    const data = { productId: productId, type: CustomerSupportSubType.AskAvailability }
    postDataText(window.config.productHelpFormUrl, data, fillPanelAndInitForm)
  })
  const attrCode = get("ask-product-availability")?.getAttribute("data-product-code");
  const attrDescription = get("product-description")?.innerHTML;
  const pCode = $("#customer-support-type-form input[name=ProductCode]") as HTMLInputElement;
  const pName = $("#customer-support-type-form input[name=ProductName]") as HTMLInputElement;
  if (!attrCode || !attrDescription || !pCode || !pName) {
    return;
  }
  pCode.value = attrCode;
  pName.value = attrCode + " - " + attrDescription.trim();
}

function initScrollToDiscussion() {
  $("[data-scroll-to]")?.scrollIntoView();
}

function initImageSlider() {
    const slider = $(".responsive-product-image-slider");
    if (!slider || !$(".product-image__list") || slider.getAttribute("data-bound")) return;
    slider.setAttribute("data-bound", "true");
     tns({
        container: ".responsive-product-image-slider",
        items: 1,
        navPosition: "bottom",
        mouseDrag: true,
        autoplay: false,
        speed: 1000,
        arrowKeys: true,
        loop: true,
        rewind: true,
        controlsContainer: ".responsive-product-image__controls",
        onInit: function () { $(".responsive-product-image-slider").querySelectorAll("li > img.d-none").forEach(e => show(e as HTMLElement)); },
        useLocalStorage: false, 
        preventScrollOnTouch: "auto",
    } as TinySliderSettings);
}

function initCustomAccordionBehaviour() {
  const shippingBlock = get("shipping") as HTMLElement;
  if (!shippingBlock) {
    return;
  }

  const fullViewPanelTogglers = shippingBlock.querySelectorAll("[data-panel-target]");
  if (fullViewPanelTogglers) {
    fullViewPanelTogglers.forEach(element => {
      if (element.hasAttribute("data-shipping-type-shop")) {
        element.addEventListener("click", () => {
          const accordionItems = shippingBlock.querySelectorAll(".accordion__item");
          accordionItems.forEach(item => {
            if (item.hasAttribute("data-shipping-type-shop")) {
              item.classList.add("accordion__item--active");
            } else {
              item.classList.remove("accordion__item--active");
            }
          });
        });
      } else {
        element.addEventListener("click", () => {
          collapseAllAccordionItems(shippingBlock);
        });
      }
    });
  }

  const responsiveViewPanelToggler = $('[data-responsive-panel-target="shipping"]');
  if (responsiveViewPanelToggler) {
    responsiveViewPanelToggler.addEventListener("click", () => {
      collapseAllAccordionItems(shippingBlock);
    });
  }
}

function collapseAllAccordionItems(element: HTMLElement) {
  const accordionItems = element.querySelectorAll(".accordion__item") as NodeListOf<Element>;
  accordionItems.forEach(item => {
    item.classList.remove("accordion__item--active");
  });
}
const expandShippingAccordion = () => {
    $$(".shipping-methods--item").forEach(item => {
        const target = item.getAttribute("data-accordion-target");
        if (!!target) {
            item.addEventListener("click", () => {
                $$(".shipping-list__item.accordion__item--active").forEach(e => e.classList.remove("accordion__item--active"));
                get(target).classList.add('accordion__item--active');
            });
        }
    });
}

function initScrollToTab() {
    const tab = $("[scroll-to-tab]");
    tab?.scrollIntoView();
    if (window.isResponsive) {
        tab?.click();
    }
    setTimeout(() => {
        tab?.removeAttribute("scroll-to-tab");
    }, 300);
}
function initDemoUp() {
    if (!window.config.demoUpId || !window.config.demoUpDomain || !getAndSetCookieConsents().performance){
        return
    }
    const stage1 = document.createElement('script');
    stage1.async = true;
    stage1.className = 'demoup_stage1_script';
    stage1.src = `//static.demoup.com/api/stages/${window.config.demoUpId}/stage1.js?url=` + encodeURI(document.URL);
    document.head.parentNode.insertBefore(stage1, document.head);
    
    const stage2 = document.createElement('script');
    stage2.async = true;
    stage2.id = 'demoup_stage2_script';
    stage2.src = `//static.demoup.com/${window.config.demoUpDomain}/stage2.min.js`;
    document.head.appendChild(stage2);
}
function trackKlaviyoPageView() {
    if (!window.config.cookieConsents.marketing || !window.config.klaviyoAccount) {
        return
    }
    const product = $(`[data-product-list-id="${EditCartFrom.ProductDetail}"]`)
    if(!product) return
    const name = document.getElementById("product-description").getAttribute("data-product-name")
    const id = product.getAttribute("data-product-id")
    const code = product.querySelector("[data-product-code]").textContent
    const categoryTree = product.getAttribute("data-product-category-tree")
    const brand = product.querySelector("[data-product-brand]")?.textContent
    const price = product.getAttribute("data-product-price")
    const item = {
        ProductName: name,
        ProductID: id,
        SKU: code,
        Categories: categoryTree,
        URL: window.location.href,
        Brand: brand.trim(),
        Price: price
    } as KlaviyoViewedProduct
    window.klaviyo.push(['track', KlaviyoEvents.ViewedProduct, item])
}

window.addEventListener("load", () => {
  initProduct();
  initTabs();
  initCustomAccordionBehaviour();
  tryOpenTabFromUrl();
  initGoogleMap();
  initCarousels();
  initImageSlider();
  initProductModals();
  initLeaseCalculator();
  initCardComparisons();
  initCardFavorites();
  initComparisons();
  initFavorites();
  initRatingStars();
  initDiscussions();
  initProductReviewButtons();
  initDisplayOriginalOrTranslated();
  initScrollToDiscussion();
  initProductAvailabilityPrompt();
  expandShippingAccordion();
  initScrollToTab();
  initDemoUp();
  trackKlaviyoPageView();
});
window.addEventListener(cookieConsentsLoaded, initDemoUp);

